<template>
  <div class="main-box">
    <el-form class="form-inline" :inline="true">
      <el-form-item label="">
        <el-select v-model="search.result_type" placeholder="请选择结果" clearable size="small">
          <el-option :key="1" label="有风险" :value="1"></el-option>
          <el-option :key="2" label="通过" :value="2"></el-option>
          <el-option :key="3" label="需人工审核" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="handleSearch()">搜索</el-button>
      </el-form-item>
    </el-form>
    <el-table
          :data="tableData"
          ref="rfTable"
          style="width: 100%"
          size="mini"
          @selection-change="handleSelectionChange">
      <el-table-column
          prop="id"
          label="ID"
          align="center"
          width="100">
      </el-table-column>
      <el-table-column
          prop="merchant_title"
          label="商户名称"
          min-width="150">
      </el-table-column>
      <el-table-column
          prop="appid"
          label="appid"
          width="150">
      </el-table-column>
      <el-table-column
          prop="member"
          label="会员"
          width="130">
        <template slot-scope="scope">
          <div style="display: flex; align-items: center">
            <el-avatar :src="scope.row.member.avatar" shape="square"></el-avatar>
            <span class="mg-lf-10">{{scope.row.member.name}}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          prop="media_type"
          label="类型"
          align="center"
          width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.media_type==1">图片</span>
          <span v-if="scope.row.media_type==2">音频</span>
          <span v-if="scope.row.media_type==3">文本</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="media_url"
          label="审核素材"
          align="center"
          width="120">
        <template slot-scope="scope">
          <el-avatar :src="scope.row.media_url" shape="square" v-if="scope.row.media_type==1"></el-avatar>
          <span v-if="scope.row.media_type==3">{{scope.row.content}}</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="status"
          label="审核状态"
          align="center"
          width="160">
        <template slot-scope="scope">
          {{scope.row.status==1?'已审核':'未审核'}}
        </template>
      </el-table-column>
      <el-table-column
          prop="result_type"
          label="结果"
          align="center"
          width="160">
        <template slot-scope="scope">
          <span v-if="scope.row.result_type==1">有风险</span>
          <span v-if="scope.row.result_type==2">通过</span>
          <span v-if="scope.row.result_type==3">需人工审核</span>
        </template>
      </el-table-column>
      <el-table-column
          prop="result_value"
          label="结果"
          align="center"
          width="160">
      </el-table-column>
      <el-table-column
          prop="ftime"
          label="添加时间"
          align="center"
          width="140">
      </el-table-column>
<!--      <el-table-column-->
<!--          fixed="right"-->
<!--          label="操作"-->
<!--          align="center"-->
<!--          width="160">-->
<!--        <template slot-scope="scope">-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <el-pagination
      background
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[10, 20, 30, 40]"
      :total="search.total">
    </el-pagination>
  </div>
</template>
<script>

export default {
  components: {
  },
  data() {
    return {
      source_domain: this.config.SOURCE_DOMAIN,
      search: {
        current_page: 1,
        total: 0,
        limit: 10,
        result_type: '',
      },
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
  },
  computed: {
  },
  methods: {
    getList() {
      var that = this
      that.showLoading()
      var param = {
        page: that.search.current_page,
        page_size: that.search.limit,
        result_type: that.search.result_type
      }
      this.$api.audit.auditContentIndex(param, function (res) {
        that.hideLoading()
        if(res.errcode == 0) {
          that.tableData = res.data.data
          that.search.current_page = res.data.current_page
          that.search.total = res.data.total
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    handleSearch() {
      this.search.current_page = 1
      this.getList()
    },
    handleSelectionChange(rows) {
    },
    handleCurrentChange(val) {
      this.search.current_page = val
      this.getList()
    },
    handleSizeChange(val) {
      this.search.current_page = 1
      this.search.limit = val
      this.getList()
    }
  }
};
</script>
<style scoped>
</style>
